import mutations from './mutations';
import actions from './actions';

const state = {
  articles: null,
  categories: null,
  sections: null,
  formList: null,
  selectedForm: null,
  loading: false,
  error: null,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
