import axios, { AxiosResponse } from 'axios';
import { logError } from '../../index';
import CONSTANTS from './constants';
import facebookActions from './credentials/facebook';
import siteImpactActions from './credentials/siteimpact';

export default {
  ...facebookActions,
  ...siteImpactActions,
  async getAgencies({ commit }): Promise<object> {
    try {
      commit(CONSTANTS.SET_AGENCIES, null);
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get('/api/getAgencies/');
      if (req.data?.error) {
        commit(CONSTANTS.SET_ERROR, req.data.error);
        return;
      }
      commit(CONSTANTS.SET_AGENCIES, req.data);
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.agencyadmin.getAgencies');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async getGoogleCredentialConnectionsNew({ commit }, payload): Promise<object> {
    try {
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(`/api/getGoogleCredentialConnectionsNew/?id=${payload.id}`);
      if (req.data?.error) {
        commit(CONSTANTS.SET_ERROR, req.data.error || req.data.message);
        return;
      }
      if (req.data?.message) {
        if (req.data.message.toLowerCase() === 'no accounts linked') return [];
        throw logError(req.data.message, 'store.agencyadmin.getGoogleCredentialConnectionsNew');
      }
      return req.data;
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.agencyadmin.getGoogleCredentialConnectionsNew');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },

  async googleCredentialsRemoveTokensNew({ commit }, payload): Promise<object> {
    if (!payload.agencyId || !payload.googleUserId) {
      commit(CONSTANTS.SET_ERROR, 'Missing required parameters');
      return;
    }
    try {
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(
        `/api/googleCredentialsRemoveTokensNew/?agencyId=${payload.agencyId}&googleUserId=${payload.googleUserId}`,
      );
      if (req.data?.error || req.data?.message) {
        commit(CONSTANTS.SET_ERROR, req.data.error || req.data.message);
        return;
      }
      return req.data;
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.agencyadmin.googleCredentialsRemoveTokensNew');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getGoogleCredentialsLink({ commit }, payload): Promise<string> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/getGoogleCredentialsLink', payload);
      return req.data;
    } catch (exp) {
      return exp.message;
    }
  },
  async getSiteimpactCampaigns({ commit }, payload): Promise<object> {
    try {
      commit(CONSTANTS.SET_SITEIMPACT_LOADING, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(
        `/api/siteimpactCampaigns/${payload.id}/${payload.keyid}/${payload.agencyowned}`,
      );
      if (req.data?.error) {
        commit(CONSTANTS.SET_SITEIMPACT_ERROR, req.data.error || req.data.message);
        return req.data;
      }
      if (req.data?.message) {
        if (req.data.message.toLowerCase() === 'no accounts linked') return [];
        throw logError(req.data.message, 'store.agencyadmin.getSiteimpactGetConnections');
      }
      return req.data;
    } catch (exp) {
      commit(CONSTANTS.SET_SITEIMPACT_ERROR, exp.message);
      throw logError(exp, 'store.agencyadmin.getSiteimpactGetConnections');
    } finally {
      commit(CONSTANTS.SET_SITEIMPACT_LOADING, false);
    }
  },
};
