export default {
  SET_SUPPORT_CENTER_ERROR: 'SET_SUPPORT_CENTER_ERROR',
  SET_SUPPORT_CENTER_LOADING: 'SET_SUPPORT_CENTER_LOADING',
  SET_SUPPORT_CENTER_ARTICLE: 'SET_SUPPORT_CENTER_ARTICLE',
  SET_SUPPORT_CENTER_ARTICLES: 'SET_SUPPORT_CENTER_ARTICLES',
  SET_SUPPORT_CENTER_WHATS_NEW_ARTICLE: 'SET_SUPPORT_CENTER_WHATS_NEW_ARTICLE',
  SET_SUPPORT_CENTER_SECTION: 'SET_SUPPORT_CENTER_SECTION',
  SET_SUPPORT_CENTER_SECTIONS: 'SET_SUPPORT_CENTER_SECTIONS',
  SET_SUPPORT_CENTER_CATEGORIES: 'SET_SUPPORT_CENTER_CATEGORY',
  SET_SUPPORT_CENTER_FORMS: 'SET_SUPPORT_CENTER_FORMS',
  SET_SUPPORT_CENTER_FORM: 'SET_SUPPORT_CENTER_FORM',
};
