import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  // advertiserInfo
  [CONSTANTS.SET_ADVERTISER_INFO](state: StateType, advertiserInfo): void {
    Vue.set(state, 'advertiserInfo', advertiserInfo);
  },
  [CONSTANTS.SET_ADVERTISER_INFO_ERROR](state: StateType, error): void {
    Vue.set(state, 'advertiserInfoError', error);
  },
  [CONSTANTS.SET_ADVERTISER_INFO_CACHE](state: StateType, advertiserInfoUrl): void {
    Vue.set(state, 'advertiserInfoCache', advertiserInfoUrl);
  },
  [CONSTANTS.SET_IS_ADVERTISER_INFO_CACHED](state: StateType, isCached): void {
    Vue.set(state, 'isAdvertiserInfoCached', isCached);
  },
  [CONSTANTS.SET_IS_ADVERTISER_INFO_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isAdvertiserInfoLoading', isLoading);
  },
  // contacts
  [CONSTANTS.SET_CONTACTS](state: StateType, contacts): void {
    Vue.set(state, 'contacts', contacts);
  },
  [CONSTANTS.SET_CONTACTS_ERROR](state: StateType, error): void {
    Vue.set(state, 'contactsError', error);
  },
  [CONSTANTS.SET_IS_CONTACTS_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isContactsLoading', isLoading);
  },
  // update advertiser
  [CONSTANTS.SET_UPDATE_ADVERTISER_ERROR](state: StateType, error): void {
    Vue.set(state, 'updateAdvertiserError', error);
  },
  [CONSTANTS.SET_IS_UPDATE_ADVERTISER_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isUpdateAdvertiserLoading', isLoading);
  },
  // update contacts
  [CONSTANTS.SET_UPDATE_CONTACTS_ERROR](state: StateType, error): void {
    Vue.set(state, 'updateContactsError', error);
  },
  [CONSTANTS.SET_IS_UPDATE_CONTACTS_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isUpdateContactsLoading', isLoading);
  },
  // Conversions
  [CONSTANTS.SET_CONVERSIONS](state: StateType, conversions): void {
    Vue.set(state, 'conversions', conversions);
  },
  [CONSTANTS.SET_CONVERSIONS_ERROR](state: StateType, error): void {
    Vue.set(state, 'conversionsError', error);
  },
  [CONSTANTS.SET_IS_CONVERSIONS_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isConversionsLoading', isLoading);
  },
};
