import { ApolloQueryResult } from '@apollo/client/core';
import gql from 'graphql-tag';
import { apollo } from '../../index';
import {
  CampaignByProductResponse,
  CampaignResponse,
  AdvertiserResponse,
  AdvertisersByProductResponse,
  AgenciesResponse,
  ProductsResponse,
} from './types';

export default {
  async getProductsForAdvertiser(advertiserId: string) {
    try {
      const response: ApolloQueryResult<ProductsResponse> = await apollo().query({
        query: gql`
          query GetProductsForAdvertiser($getProductsForAdvertiserId: ID) {
            getProductsForAdvertiser(id: $getProductsForAdvertiserId) {
              id
            }
          }
        `,
        variables: {
          getProductsForAdvertiserId: advertiserId,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getAdvertisers(props: { search: string; page: string; partner: string }) {
    try {
      const response: ApolloQueryResult<AdvertiserResponse> = await apollo().query({
        query: gql`
          query LookupAdvertiser(
            $name: String
            $page: String
            $partner: String
            $station: String
            $sortBy: String
            $sortDesc: Boolean
          ) {
            lookupAdvertiser(
              name: $name
              page: $page
              partner: $partner
              station: $station
              sortBy: $sortBy
              sortDesc: $sortDesc
            ) {
              PageSize
              Page
              TotalPages
              Records
              TotalRecords
              ClientList {
                name
                PropertyId
                AgencyPartnerName
              }
              Facets {
                DisplayName
                Key
                Elements {
                  Count
                  Value
                }
              }
            }
          }
        `,
        variables: {
          name: props.search,
          page: props.page,
          partner: props.partner,
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getAdvertisersByProduct(advertiserName: string, productName: string, agency: string) {
    try {
      const response: ApolloQueryResult<AdvertisersByProductResponse> = await apollo().query({
        query: gql`
          query GetAdvertisersByProduct($params: AdvertisersByProductInput) {
            getAdvertisersByProduct(params: $params) {
              List {
                Agency
                Name
                PropertyId
              }
              Total
            }
          }
        `,
        variables: {
          params: {
            agency: agency || '',
            name: advertiserName,
            productName: productName || '',
          },
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getCampaigns(advertiserId: string) {
    try {
      const response: ApolloQueryResult<CampaignResponse> = await apollo().query({
        query: gql`
          query GetCampaigns($advertiserId: ID) {
            getCampaigns(id: $advertiserId) {
              PropertyId
              Campaign {
                id
                name
                CampaignType
                FriendlyName
              }
              StartDate
              EndDate
              DateRange
            }
          }
        `,
        variables: {
          advertiserId,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getCampaignByProduct(campaignName: string, productName: string) {
    try {
      const response: ApolloQueryResult<CampaignByProductResponse> = await apollo().query({
        query: gql`
          query GetCampaignByProduct($params: CampaignByProductInput) {
            getCampaignByProduct(params: $params) {
              List {
                AdvertiserId
                Agency
                AnalyticsType
                ClientName
                Name
                PropertyId
              }
              Total
            }
          }
        `,
        variables: {
          params: {
            name: campaignName,
            page: '0',
            productName: productName,
          },
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getAgencies() {
    try {
      const response: ApolloQueryResult<AgenciesResponse> = await apollo().query({
        query: gql`
          query GetAgencies {
            getAgencies {
              List {
                name
                originId
              }
            }
          }
        `,
        variables: {},
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
};
