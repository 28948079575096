import { isAfter, isBefore } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { StatusAlert, WhatsNew } from '../types/status';
import util from '../util';

export default {
  computed: {
    tacticAlerts(): Array<StatusAlert> {
      return util.ensureArray(this.$store.state?.status?.report?.alerts).filter((x: StatusAlert) => {
        // check if system, tab, route name, or shared tab match alert location.
        if (
          x.location?.toLowerCase() === 'system' ||
          x.location?.toLowerCase() === this.$route?.query?.tab?.toLowerCase() ||
          x.location?.toLowerCase() === this.$route?.name?.toLowerCase() ||
          x.location?.toLowerCase() === this.$store.state?.customer?.sharedSelection?.tab?.toLowerCase()
        ) {
          if (this.checkActive(x, null)) return x;
        }
        return;
      });
    },
    dashboardAlertActive(): boolean {
      return this.tacticAlerts.length > 0;
    },
    whatNewActive(): boolean {
      // do not show pop up on login or shared pages
      if (this.$route?.name?.toLowerCase() === 'login' || this.$store.state.customer?.sharedDashboard) return false;
      return this.checkActive(null, this.$store.state?.status?.report?.whatsnew);
    },
    whatNewCurrent(): boolean {
      // do not show pop up on login or shared pages
      if (this.$route?.name?.toLowerCase() === 'login' || this.$store.state.customer?.sharedDashboard) return null;
      return this.$store.state?.status?.report?.whatsnew;
    },
  },
  methods: {
    checkActiveById(id: number): boolean {
      const alertExists = this.$store.state?.status?.report?.alerts.findIndex((alert: StatusAlert) => alert.id === id);
      let currentAlert = null;
      if (alertExists !== -1) {
        currentAlert = this.$store.state.status.report.alerts[alertExists];
      } else {
        return false;
      }
      return this.checkActive(currentAlert, null);
    },
    checkActive(currentAlert: StatusAlert, currentWhatsNew: WhatsNew): boolean {
      const notification = currentAlert || currentWhatsNew;
      // alert is active.
      const active = notification?.active;
      if (!active) return false;
      // alert dismissed by user and not on admin page.
      if (this.$route?.name !== 'adminstatus' && this.$store.state?.status?.dismiss && currentAlert) {
        const dismissedAlert = this.$store.state.status.dismiss.findIndex(
          (alert: StatusAlert) => alert.id === currentAlert.id,
        );
        // if we find a dismissed alert
        if (dismissedAlert !== -1) {
          return false;
        }
      }
      const now = Date.now();
      let utcStartTime, startActive, utcEndTime, endActive;

      // combine start date and time.
      const startTime =
        notification?.start?.utc && notification?.start?.time
          ? `${notification.start.utc} ${notification.start.time}`
          : null;

      if (startTime) {
        // convert start time to UTC
        utcStartTime = zonedTimeToUtc(startTime, notification.timezone);
        // check if start time is after now.
        startActive = isAfter(now, utcStartTime);
      }

      // combine end date and time.
      const endTime =
        notification?.end?.utc && notification?.end?.time ? `${notification.end.utc} ${notification.end.time}` : null;

      if (endTime) {
        // convert end time to UTC
        utcEndTime = zonedTimeToUtc(endTime, notification?.timezone);
        // check if end time is after now.
        endActive = isBefore(now, utcEndTime);
      }

      // if a startTime is set, check if message falls within range
      if (startTime && active) {
        // if endTime is set
        if (endTime) return endActive && startActive;
        return startActive;
      }
      // endtime is set
      if (endTime && active) return endActive;
      // if no times are set, check if alert is active.
      return active;
    },
  },
};
