import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  [CONSTANTS.SET_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'loading', loading);
  },
  [CONSTANTS.SET_ERROR](state: StateType, error: string): void {
    Vue.set(state, 'error', error);
  },
  [CONSTANTS.SET_AGENCIES](state: StateType, agencies: object): void {
    Vue.set(state, 'agencies', agencies);
  },
  [CONSTANTS.SET_FACEBOOK_ERROR](state: StateType, error: string): void {
    Vue.set(state, 'facebookError', error);
  },
  [CONSTANTS.SET_FACEBOOK_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'facebookLoading', loading);
  },
  [CONSTANTS.SET_NEW_FACEBOOK_ERROR](state: StateType, error: string): void {
    Vue.set(state.credentials, 'facebookError', error);
  },
  [CONSTANTS.SET_NEW_FACEBOOK_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state.credentials, 'facebookNewLoading', loading);
  },
  [CONSTANTS.SET_SITEIMPACT_ERROR](state: StateType, error: string): void {
    Vue.set(state, 'siteImpactError', error);
  },
  [CONSTANTS.SET_SITEIMPACT_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'siteImpactLoading', loading);
  },
  [CONSTANTS.SET_SITEIMPACT_SAVING](state: StateType, loading: boolean): void {
    Vue.set(state, 'siteImpactSaving', loading);
  }
};
