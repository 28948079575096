import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  [CONSTANTS.SET_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'loading', loading);
  },
  [CONSTANTS.SET_ERROR](state: StateType, error: string): void {
    Vue.set(state, 'error', error);
  },
  [CONSTANTS.SET_REPORTS](state: StateType, reports: object): void {
    Vue.set(state, 'reports', reports);
  },
  [CONSTANTS.SET_PRODUCTS](state: StateType, products: object): void {
    Vue.set(state, 'products', products);
  },
};
