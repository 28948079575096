import CONSTANTS from '../constants';
import axios, { AxiosResponse } from 'axios';
import { logError } from '../../../index';

export default {
  async getFacebookCredentials({ commit }, payload): Promise<object> {
    try {
      commit(CONSTANTS.SET_NEW_FACEBOOK_LOADING, true);
      commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, null);
      const params = {
        agencyId: payload.id,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(`/api/getFacebookCredentials?agencyId=${params.agencyId}`);
      if (req.data?.error) {
        commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, req.data.error || req.data.message);
        return;
      }
      if (req.data?.message) {
        if (req.data.message.toLowerCase() === 'no accounts linked') return [];
        throw logError(req.data.message, 'store.agencyadmin.getFacebookCredentialConnections');
      }
      return req.data;
    } catch (exp) {
      commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, exp.message);
      throw logError(exp, 'store.agencyadmin.getFacebookCredentialConnections');
    } finally {
      commit(CONSTANTS.SET_NEW_FACEBOOK_LOADING, false);
    }
  },

  async addFacebookCredential({ commit }, payload): Promise<void> {
    try {
      commit(CONSTANTS.SET_NEW_FACEBOOK_LOADING, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/addFacebookCredential', payload);
      if (req.data.error) {
        commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, req.data.error);
        return;
      }
      return req.data;
    } catch (exp) {
      commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, exp.message);
      throw logError(exp, 'store.agencyadmin.addFacebookCredential');
      return;
    } finally {
      commit(CONSTANTS.SET_NEW_FACEBOOK_LOADING, false);
    }
  },
  async removeFacebookCredential({ commit }, payload): Promise<void> {
    try {
      commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, null);
      commit(CONSTANTS.SET_NEW_FACEBOOK_LOADING, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post(`/api/removeFacebookCredential`, payload);
      if (req.data.error) {
        commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, req.data.error);
        return;
      }
      return req.data;
    } catch (exp) {
      commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, exp.message);
      throw logError(exp, 'store.linearadmin.removeFacebookCredential');
    } finally {
      commit(CONSTANTS.SET_NEW_FACEBOOK_LOADING, false);
    }
  },
  async syncFacebookCredential({ commit }, payload): Promise<void> {
    try {
      commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, null);
      commit(CONSTANTS.SET_NEW_FACEBOOK_LOADING, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post(`/api/syncFacebookCredential`, payload);
      if (req.data.error) {
        commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, req.data.error);
        return;
      }
      return req.data;
    } catch (exp) {
      commit(CONSTANTS.SET_NEW_FACEBOOK_ERROR, exp.message);
      throw logError(exp, 'store.linearadmin.syncFacebookCredential');
    } finally {
      commit(CONSTANTS.SET_NEW_FACEBOOK_LOADING, false);
    }
  },
};
