import { ApolloQueryResult } from '@apollo/client/core';
import { logError } from '../../index';
import CONSTANTS from './constants';
import requests from './requests';
import axios, { AxiosResponse } from 'axios';
import {
  ArticleResponse,
  ArticlesResponse,
  SectionResponse,
  SectionsResponse,
  CategoriesResponse,
  ApolloArticleByIdResponse,
  ApolloArticleByCategoryResponse,
  ApolloArticleBySectionResponse,
  ApolloArticleBySectionIdResponse,
  ApolloSectionResponse,
  ApolloSectionsResponse,
  ApolloSectionsByCategoryResponse,
  ApolloCategoriesResponse,
  SearchArticleResponse,
  ApolloSearchArticleResponse,
  FormsResponse,
  FormResponse,
  FormRequest,
  FormRequestResponse,
  ApolloFormsResponse,
  ApolloFormRequestResponse,
  ApolloFormResponse,
  SearchParams,
  Error,
} from './types';

const getSCArticlesById = async ({ commit }, articleId: string): Promise<ArticleResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloArticleByIdResponse> = await requests.getSCArticlesById(articleId);
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(CONSTANTS.SET_SUPPORT_CENTER_ARTICLES, [response.data?.getZenDeskArticleById.article]);
    return response.data?.getZenDeskArticleById;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSCArticlesById', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const getSCArticlesByCategory = async ({ commit }, categoryId: string): Promise<ArticlesResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloArticleByCategoryResponse> = await requests.getSCArticlesByCategory(
      categoryId,
    );
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(CONSTANTS.SET_SUPPORT_CENTER_ARTICLES, response.data?.getZenDeskArticlesByCategory?.articles);
    return response.data?.getZenDeskArticlesByCategory;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSCArticlesByCategory', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const getSCArticlesBySection = async ({ commit }, sectionId: string): Promise<ArticlesResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloArticleBySectionResponse> = await requests.getSCArticlesBySection(
      sectionId,
    );
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(CONSTANTS.SET_SUPPORT_CENTER_ARTICLES, response.data?.getZenDeskArticlesBySection);
    return response.data?.getZenDeskArticlesBySection;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSCArticlesBySection', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const getSCFirstArticleBySectionId = async ({ commit }, sectionId: string): Promise<ArticleResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloArticleBySectionIdResponse> = await requests.getSCFirstArticleBySectionId(
      sectionId,
    );
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(CONSTANTS.SET_SUPPORT_CENTER_WHATS_NEW_ARTICLE, response.data?.getZenDeskFirstArticleBySectionId);
    return response.data?.getZenDeskFirstArticleBySectionId;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSCFirstArticleBySectionId', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};
const getSCSectionsById = async ({ commit }, sectionId: string): Promise<SectionResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloSectionResponse> = await requests.getSCSectionsById(sectionId);
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(CONSTANTS.SET_SUPPORT_CENTER_SECTION, response.data?.getZenDeskSectionsById);
    return response.data?.getZenDeskSectionsById;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSCSectionsById', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const getSCSectionsByCategoryId = async ({ commit }, categoryId: string): Promise<SectionsResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloSectionsByCategoryResponse> = await requests.getSCSectionsByCategoryId(
      categoryId,
    );
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(CONSTANTS.SET_SUPPORT_CENTER_SECTIONS, response.data?.getZenDeskSectionsByCategory);
    return response.data?.getZenDeskSectionsByCategory;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSCSectionsByCategoryId', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const getSCSections = async ({ commit }): Promise<SectionsResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloSectionsResponse> = await requests.getSCSections();
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(CONSTANTS.SET_SUPPORT_CENTER_SECTIONS, response.data?.getZenDeskSections.sections);
    return response.data?.getZenDeskSections;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSCSections', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const getSCCategories = async ({ commit }): Promise<CategoriesResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloCategoriesResponse> = await requests.getSCCategories();
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(CONSTANTS.SET_SUPPORT_CENTER_CATEGORIES, response.data?.getZenDeskCategories?.categories);
    return response.data?.getZenDeskCategories;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSCCategories', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const getSearchSCArticles = async ({ commit }, params: SearchParams): Promise<SearchArticleResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloSearchArticleResponse> = await requests.getSearchSCArticles(
      params.searchTerm,
      params.page,
    );
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    return response.data.getZenDeskArticlesByQuery;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSearchSCArticles', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const getSCForms = async ({ commit }): Promise<FormsResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloFormsResponse> = await requests.getSCForms();
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }

    // filter out "default" form
    const filtered = response.data?.getZenDeskTicketForms.ticket_forms.filter(f => f.name !== 'Default Ticket Form');

    commit(CONSTANTS.SET_SUPPORT_CENTER_FORMS, filtered);
    return response.data?.getZenDeskTicketForms;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSCForms', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const getSCFormById = async ({ commit }, formId: string): Promise<FormResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloFormResponse> = await requests.getSCFormById(formId);
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    commit(CONSTANTS.SET_SUPPORT_CENTER_FORM, response.data.getZenDeskTicketFormById.form_fields);
    return response.data.getZenDeskTicketFormById;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('getSCFormById', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const postSCFormRequest = async ({ commit }, request: FormRequest): Promise<FormRequestResponse | Error> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    const response: ApolloQueryResult<ApolloFormRequestResponse> = await requests.postSCFormRequest(request);
    if (response?.errors) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    return response.data.postZenDeskRequest;
  } catch (error) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, error.message);
    logError('postSCFormRequest', error.message);
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

const uploadFormAttachment = async ({ commit }, img): Promise<void> => {
  try {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, true);
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, null);

    const form = new FormData();
    form.append('file', img);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post('/api/uploadFormAttachment', form);
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, req.data.error);
      return req.data;
    }
    return req.data;
  } catch (exp) {
    commit(CONSTANTS.SET_SUPPORT_CENTER_ERROR, exp.message);
    throw logError(exp, 'uploadFormAttachment');
  } finally {
    commit(CONSTANTS.SET_SUPPORT_CENTER_LOADING, false);
  }
};

export default {
  getSCArticlesById,
  getSCArticlesByCategory,
  getSCArticlesBySection,
  getSCSectionsById,
  getSCSectionsByCategoryId,
  getSCFirstArticleBySectionId,
  getSCSections,
  getSCCategories,
  getSearchSCArticles,
  getSCForms,
  getSCFormById,
  postSCFormRequest,
  uploadFormAttachment,
};
