import mutations from './mutations';
import actions from './actions';

const state = {
  status: null,
  error: null,
  orderListParams: {
    limit: 10,
    offset: 0,
    sortBy: 'end_asc',
    filterBy: 'all',
    orderName: '',
  },
  orderParams: {
    orderId: null,
    daterange: 'thismonth',
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
