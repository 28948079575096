import Vue from 'vue';
import Router from 'vue-router';
import NotFound from './pages/global/404.vue';
import store from './store';
import utils from './util';
import { checkPermissions } from '@point/auth';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      name: 'landingPage',
      component: () => import(/* webpackChunkName: "landingPage" */ './pages/landingPage/landingPage.vue'),
    },
    {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './pages/home/home.vue'),
      meta: {
        requiresAuth: true,
        returnable: true,
        title: 'Home',
      },
    },
    {
      path: '/advertiser',
      name: 'advertiser',
      component: () => import(/* webpackChunkName: "advertiser" */ './pages/advertiser/index.vue'),
      meta: {
        requiresAuth: true,
        returnable: true,
        title: 'Advertiser View',
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import(/* webpackChunkName: "advertiser" */ './pages/advertiser/advertisersForOrdersPage.vue'),
      meta: {
        requiresAuth: true,
        returnable: true,
        title: 'Orders',
      },
    },
    {
      path: '/shared-preview',
      name: 'share',
      component: () => import(/* webpackChunkName: "share" */ './pages/home/share.vue'),
    },
    {
      name: 'admin',
      path: '/admin',
      component: () => import(/* webpackChunkName: "admin" */ './pages/admin/index.vue'),
      redirect: {
        name: 'adminusermanagement',
      },
      children: [
        {
          path: 'feed-tools',
          name: 'adminfeedtools',
          component: () => import(/* webpackChunkName: "adminstatus" */ './pages/systemAdmin/feedTools.vue'),
          meta: {
            requiresAuth: true,
            returnable: true,
            title: 'Feed Tools',
            requiredRights: ['SU'],
          },
        },
      ],
      meta: {
        requiresAuth: true,
        requiredRights: ['SCREEN_USER_MGMT'],
        returnable: true,
      },
    },
    {
      name: 'systemAdmin',
      path: '/systemAdmin',
      component: () => import(/* webpackChunkName: "admin" */ './pages/systemAdmin/index.vue'),
      redirect: {
        name: 'admincache',
      },
      children: [
        {
          path: 'upload',
          name: 'adminupload',
          component: () => import(/* webpackChunkName: "adminupload" */ './pages/systemAdmin/upload.vue'),
          meta: {
            requiresAuth: true,
            returnable: true,
            title: 'System Admin - Upload',
            requiredRights: ['SU'],
          },
        },
        {
          path: 'cache',
          name: 'admincache',
          component: () => import(/* webpackChunkName: "admincache" */ './pages/systemAdmin/cache.vue'),
          meta: {
            requiresAuth: true,
            returnable: true,
            title: 'System Admin - Cache',
            requiredRights: ['SU'],
          },
        },
        {
          path: 'reports',
          name: 'adminreports',
          component: () => import(/* webpackChunkName: "adminreports" */ './pages/systemAdmin/reports.vue'),
          meta: {
            requiresAuth: true,
            returnable: true,
            title: 'System Admin - Reports',
            requiredRights: ['SU'],
          },
        },
        {
          path: 'status-alerts',
          name: 'adminstatus',
          component: () => import(/* webpackChunkName: "adminstatus" */ './pages/systemAdmin/status.vue'),
          meta: {
            requiresAuth: true,
            returnable: true,
            title: 'System Admin - Status Alerts',
            requiredRights: ['SU'],
          },
        },
        {
          path: 'configurability-log',
          name: 'adminconfigurabilitylog',
          component: () => import(/* webpackChunkName: "adminstatus" */ './pages/systemAdmin/configurabilityLog.vue'),
          meta: {
            requiresAuth: true,
            returnable: true,
            title: 'System Admin - Configurability Log',
            requiredRights: ['SU'],
          },
        },
        {
          path: 'white-labels',
          name: 'adminwhitelabels',
          component: () => import(/* webpackChunkName: "adminstatus" */ './pages/systemAdmin/whiteLabels.vue'),
          meta: {
            requiresAuth: true,
            returnable: true,
            title: 'System Admin - White Labels',
            requiredRights: ['SU'],
          },
        },
      ],
      meta: {
        requiresAuth: true,
        requiredRights: ['SU'],
        returnable: true,
      },
    },
    {
      name: 'agencyAdmin',
      path: '/agencyAdmin',
      meta: {
        requiresAuth: true,
        requiredRights: ['SCREEN_AGENCY_MGMT'],
        returnable: true,
      },
      component: () => import(/* webpackChunkName: "adminusermanagement" */ './pages/agencyAdmin/index.vue'),
      children: [
        {
          path: 'agencylist',
          name: 'agencylist',
          component: () => import(/* webpackChunkName: "agencylist" */ './pages/agencyAdmin/agencyList.vue'),
          meta: {
            requiresAuth: true,
            requiredRights: ['SCREEN_AGENCY_MGMT'],
            returnable: true,
            title: 'Agency Admin - List',
          },
        },
        {
          path: 'agency/:agency',
          name: 'agency',
          component: () => import(/* webpackChunkName: "agencylist" */ './pages/agencyAdmin/agency.vue'),
          meta: {
            requiresAuth: true,
            requiredRights: ['SCREEN_AGENCY_MGMT'],
            returnable: true,
            title: 'Agency Admin - Edit',
          },
        },
      ],
    },
    {
      name: 'linearadmin',
      path: '/linearadmin',
      meta: {
        requiresAuth: true,
        requiredRights: ['SCREEN_ADVERTISER_MGMT'],
        returnable: true,
        title: 'Advertiser Management',
      },
      component: () => import(/* webpackChunkName: "linearadmin" */ './pages/linearadmin/index.vue'),
    },
    {
      name: 'reportsadmin',
      path: '/reportsadmin',
      meta: {
        requiresAuth: true,
        requiredRights: ['AGENCY_EDIT_SCHEDULED_REPORT'],
        advertiserRight: 'EDIT_SCHEDULED_REPORT',
        returnable: true,
      },
      component: () => import(/* webpackChunkName: "reportsadmin" */ './pages/scheduledreports/index.vue'),
      children: [
        {
          name: 'scheduledreports',
          path: 'reports',
          component: () => import(/* webpackChunkName: "scheduledreports" */ './pages/scheduledreports/list.vue'),
          meta: {
            requiresAuth: true,
            returnable: true,
            title: 'Scheduled Reports - List',
          },
        },
        {
          name: 'scheduledreport',
          path: 'report',
          component: () => import(/* webpackChunkName: "scheduledreports" */ './pages/scheduledreports/report.vue'),
          meta: {
            requiresAuth: true,
            returnable: true,
            title: 'Scheduled Reports - Edit',
          },
        },
      ],
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "logout" */ './pages/login/logout.vue'),
    },
    {
      name: 'denied',
      path: '/denied',
      component: () => import(/* webpackChunkName: "denied" */ './pages/login/denied.vue'),
    },
    {
      name: 'login',
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ './pages/login/login.vue'),
    },
    {
      name: 'ahlogin',
      path: '/ahlogin',
      component: () => import(/* webpackChunkName: "login" */ './pages/login/loginAH.vue'),
    },
    {
      name: 'privacy',
      path: '/privacy-policy',
      component: () => import(/* webpackChunkName: "privacy" */ './pages/legal/privacyPolicy.vue'),
    },
    {
      name: 'terms',
      path: '/terms-of-use',
      component: () => import(/* webpackChunkName: "terms" */ './pages/legal/termsOfUse.vue'),
    },
    {
      name: 'grantaccess',
      path: '/grant-access',
      component: () => import(/* webpackChunkName: "grantaccess" */ './pages/access/gaAccess.vue'),
    },
    {
      name: 'fbgrantaccess',
      path: '/facebook-grant-access',
      component: () => import(/* webpackChunkName: "fbgrantaccess" */ './pages/access/fbGaAccess.vue'),
    },
    {
      name: 'healthchecks',
      path: '/healthchecks/:file',
      component: () => import(/* webpackChunkName: "healthchecks" */ './pages/tools/healthchecks.vue'),
    },
    {
      name: 'filexport',
      path: '/export/:token',
      component: () => import(/* webpackChunkName: "filexport" */ './pages/home/filexport.vue'),
    },
    {
      name: 'googlecredentialsnew',
      path: '/grant-google-access-new',
      component: () => import(/* webpackChunkName: "grantaccess" */ './pages/access/credentialsAccessNew.vue'),
    },
    {
      path: '/s/:shortId',
      name: 'shortId',
      component: () => import(/* webpackChunkName: "share" */ './pages/home/share.vue'),
    },
    { path: '*', component: NotFound },
  ],
});

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const afterCheck = async (user: any, isShared: boolean) => {
    try {
      if (!isShared) {
        const loggedInUser = user && user.email;
        // Save users last page in localstorage
        if (loggedInUser) {
          const key = `${user.email}:recentPages`;
          // checking for pages that are not tactic pages and should be saved
          if (
            to.meta.returnable &&
            to.meta?.title &&
            !to.query?.id &&
            !to.query?.viewCampaigns &&
            to.fullPath !== '/home'
          ) {
            utils.saveRoutes(to, to.meta?.title || '', key);
          }
        }
        // if a user is not logged in, on C360 domain and route is landingPage
        // redirect to login page
        if (!loggedInUser && to.name === 'landingPage') {
          next({
            name: 'login',
            query: { nextUrl: to.fullPath },
          });
          return;
        }
        // if user don't have permission, redirect from orderlist to summary
        if (loggedInUser && (to?.query?.tab === 'orderlist' || to?.query?.tab === 'ordersummary')) {
          if (
            !utils.hasRight(store.getters.user, ['SU'], true) &&
            !utils.hasRight(store.getters.user, ['SHOW_ORDER_SUMMARY_VIEW'], true)
          ) {
            next({
              name: to.name,
              query: { ...to.query, tab: 'summary' },
              params: to.params,
            });
            return;
          }
        }
        if (loggedInUser && to?.name === 'orders') {
          if (
            !utils.hasRight(store.getters.user, ['SU'], true) &&
            !utils.hasRight(store.getters.user, ['SHOW_ORDER_SUMMARY_VIEW'], true)
          ) {
            next({
              name: 'home',
              query: { recent: 'true' },
              params: to.params,
            });
            return;
          }
        }
        // if a user is already logged in
        if ((to.name === 'landingPage' || to.name === 'login') && loggedInUser) {
          const key = `${user.email}:recentPages`;
          let localPages = utils.getRoutes(key);
          if (from.name === 'ahlogin') {
            localPages = { name: 'home', query: { recent: 'true' } };
          }
          next({
            name: localPages.name,
            query: localPages?.query,
            params: localPages?.params,
          });
          return;
        }
        // ensure default home page has recent query.
        if (to.fullPath === '/home') {
          const query = { recent: 'true' };
          next({
            name: 'home',
            query,
          });
          return;
        }
        if (to.matched.some(record => record.meta.requiredRights)) {
          if (!user || !user.email) {
            next({
              name: 'login',
              query: { nextUrl: to.fullPath },
            });
            return;
          }
          if (checkPermissions(user.accessToken, to.matched[0].meta.requiredRights, false)) {
            next();
            return;
          }
        }

        if (to.matched.some(record => record.meta.requiresAuth)) {
          if (!loggedInUser) {
            next({
              name: 'login',
              query: { nextUrl: to.fullPath },
            });
            return;
          }

          if (user.email) {
            next();
            return;
          }

          next({ name: 'denied', query: { err: 'not_auth' } });
          return;
        }

        next();
      } else {
        // check that a token is set when page is shared and that it's not an admin page.
        if (user.etoken && !to.matched.some(record => record.meta.requiresAuth)) {
          next();
          return;
        }
        next({ name: 'denied', query: { err: 'not_auth' } });
        return;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('router.beforeEach.afterCheck', err);
    }
  };
  try {
    const userCheck = store.getters.user;
    // if page is shared, pass shared data
    if (store.getters.sharedSelection) {
      afterCheck(store.getters.sharedSelection, true);
    } else if (!userCheck || !userCheck.auth) {
      store
        .dispatch('getAuth')
        .then(user => {
          afterCheck(user, false);
        })
        .catch((error: Error) => {
          store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
        });
    } else {
      afterCheck(userCheck, false);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('router.beforeEach', err);
  }
});
export default router;
