import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  [CONSTANTS.SET_SUPPORT_CENTER_LOADING](state: StateType, loading: boolean): void {
    Vue.set(state, 'loading', loading);
  },
  [CONSTANTS.SET_SUPPORT_CENTER_ERROR](state: StateType, error: object | null | string): void {
    Vue.set(state, 'error', error);
  },
  [CONSTANTS.SET_SUPPORT_CENTER_ARTICLES](_state: StateType, articles: object): void {
    Vue.set(_state, 'articles', articles);
  },
  [CONSTANTS.SET_SUPPORT_CENTER_ARTICLE](_state: StateType, article: object): void {
    Vue.set(_state, 'selectedArticle', article);
  },
  [CONSTANTS.SET_SUPPORT_CENTER_WHATS_NEW_ARTICLE](_state: StateType, whatsnew: object): void {
    Vue.set(_state, 'whatsNewArticle', whatsnew);
  },
  [CONSTANTS.SET_SUPPORT_CENTER_SECTION](_state: StateType, section: object): void {
    Vue.set(_state, 'supportSection', section);
  },
  [CONSTANTS.SET_SUPPORT_CENTER_SECTIONS](_state: StateType, sections: object): void {
    Vue.set(_state, 'sections', sections);
  },
  [CONSTANTS.SET_SUPPORT_CENTER_CATEGORIES](_state: StateType, categories: object): void {
    Vue.set(_state, 'categories', categories);
  },
  [CONSTANTS.SET_SUPPORT_CENTER_FORMS](_state: StateType, forms: object): void {
    Vue.set(_state, 'formList', forms);
  },
  [CONSTANTS.SET_SUPPORT_CENTER_FORM](_state: StateType, form: object): void {
    Vue.set(_state, 'selectedForm', form);
  },
};
