export default {
  SET_ADVERTISERS: 'SET_ADVERTISERS',
  SET_ERROR: 'SET_ERROR',
  SET_USER: 'SET_USER',
  SET_DEBUG_FLAG: 'SET_DEBUG_FLAG',
  SET_DATERANGES: 'SET_DATERANGES',
  SET_SHARED_DATERANGES: 'SET_SHARED_DATERANGES',
  SET_SHARED_CAMPAIGNS: 'SET_SHARED_CAMPAIGNS',
  SET_AD_PERFORMANCE: 'SET_AD_PERFORMANCE',
  SET_STATIC_LAST_MODIFIED_DATE: 'SET_STATIC_LAST_MODIFIED_DATE',
  SET_SUMMARY_PERFORMANCE: 'SET_SUMMARY_PERFORMANCE',
  SET_TACTIC_SUMMARY_PERFORMANCE: 'SET_TACTIC_SUMMARY_PERFORMANCE',
  SET_CAMPAIGN_AD_PERFORMANCE: 'SET_CAMPAIGN_AD_PERFORMANCE',
  SET_ALL_TIME_AD_PERFORMANCE: 'SET_ALL_TIME_AD_PERFORMANCE',
  SET_ALL_TIME_CAMPAIGNS: 'SET_ALL_TIME_CAMPAIGNS',
  SET_DMA_PERFORMANCE: 'SET_DMA_PERFORMANCE',
  SET_AD_PERFORMANCE_LOADING: 'SET_AD_PERFORMANCE_LOADING',
  SET_PRODUCTS_LOADING: 'SET_PRODUCTS_LOADING',
  SET_HAS_RIGHT_LOADING: 'SET_HAS_RIGHT_LOADING',
  SET_ALL_TIME_AD_PERFORMANCE_LOADING: 'SET_ALL_TIME_AD_PERFORMANCE_LOADING',
  SET_CAMPAIGN_AD_PERFORMANCE_LOADING: 'SET_CAMPAIGN_AD_PERFORMANCE_LOADING',
  SET_SUMMARY_PERFORMANCE_LOADING: 'SET_SUMMARY_PERFORMANCE_LOADING',
  SET_TACTIC_SUMMARY_PERFORMANCE_LOADING: 'SET_TACTIC_SUMMARY_PERFORMANCE_LOADING',
  SET_EXPORT_CONTENT: 'SET_EXPORT_CONTENT',
  SET_EXPORT_CONTENT_LOADING: 'SET_EXPORT_CONTENT_LOADING',
  SET_NETWORK_ACTIVITY: 'SET_NETWORK_ACTIVITY',
  SET_DASHBOARD_SHARED: 'SET_DASHBOARD_SHARED',
  SET_SHARE_CONTENT_LOADING: 'SET_SHARE_CONTENT_LOADING',
  SET_DASHBOARD: 'SET_DASHBOARD',
  SET_LAYOUT: 'SET_LAYOUT',
  SET_LAYOUT_TYPE: 'SET_LAYOUT_TYPE',
  SET_LAYOUT_AGENCY_NAME: 'SET_LAYOUT_AGENCY_NAME',
  SET_LAYOUT_ADVERTISER_ID: 'SET_LAYOUT_ADVERTISER_ID',
  SET_LAYOUT_VERSION: 'SET_LAYOUT_VERSION',
  SET_DEFAULT_LAYOUT: 'SET_DEFAULT_LAYOUT',
  SET_LAYOUT_TACTICS: 'SET_LAYOUT_TACTICS',
  SET_LAYOUT_SOURCE: 'SET_LAYOUT_SOURCE',
  SET_LAYOUT_LOADING: 'SET_LAYOUT_LOADING',
  SET_OVERRIDES: 'SET_OVERRIDES',
  ADD_MODULE: 'ADD_MODULE',
  SET_SELECTION: 'SET_SELECTION',
  SET_SHARED_SELECTION: 'SET_SHARED_SELECTION',
  SET_ADBLOCK: 'SET_ADBLOCK',
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_CURRENT_SECTION: 'SET_CURRENT_SECTION',
  SET_NAV_TAB: 'SET_NAV_TAB',
  SET_THEME: 'SET_THEME',
  SET_EDIT_THEME: 'SET_EDIT_THEME',
  SET_CAMPAIGNS: 'SET_CAMPAIGNS',
  UPDATE_CAMPAIGNS: 'UPDATE_CAMPAIGNS',
  UPDATE_ALL_FILTERS: 'UPDATE_ALL_FILTERS',
  SET_POLYGONS: 'SET_POLYGONS',
  SET_POLYGONS_LOADING: 'SET_POLYGONS_LOADING',
  SET_DMA_POLYGONS: 'SET_DMA_POLYGONS',
  SET_DMA_POLYGONS_LOADING: 'SET_DMA_POLYGONS_LOADING',
  SET_ZIP_POLYGONS: 'SET_ZIP_POLYGONS',
  SET_ZIP_POLYGONS_LOADING: 'SET_ZIP_POLYGONS_LOADING',
  SET_GOOGLE_CREDENTIALS: 'SET_GOOGLE_CREDENTIALS',
  SET_VALIDATED_PRODUCT_SECTIONS: 'SET_VALIDATED_PRODUCT_SECTIONS',
  SET_GQL_LOADING: 'SET_GQL_LOADING',
  SET_NOTE_ERROR: 'SET_NOTE_ERROR',
  SET_EXPORT_STATE: 'SET_EXPORT_STATE',
  SET_SUMMARY_DATE_RANGES: 'SET_SUMMARY_DATE_RANGES',
  SET_CAMPAIGN_SPEND_RATE: 'SET_CAMPAIGN_SPEND_RATE',
};
