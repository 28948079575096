import gql from 'graphql-tag';
import { logError, apollo } from '../../index';
import CONSTANTS from './constants';
import { EditComponentProps } from './types';
import { Customization } from '../../../types/layout';

export default {
  editComponent({ commit }, payload: EditComponentProps): void {
    try {
      commit(CONSTANTS.SET_COMPONENT, payload.componentId);
      commit(CONSTANTS.SET_SECTION, payload.sectionId);
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.layoutEditor.editComponent');
    }
  },
  setCurrCustomizations({ commit }, customizations: Array<Customization>): void {
    try {
      commit(CONSTANTS.SET_CURR_CUSTOMIZATIONS, customizations);
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.layoutEditor.setCurrCustomizations');
    }
  },
  closeEditComponent({ commit }): void {
    commit(CONSTANTS.SET_COMPONENT, null);
    commit(CONSTANTS.SET_SECTION, null);
  },
  setEnableSave({ commit }, enableSave: boolean): void {
    commit(CONSTANTS.ENABLE_SAVE, enableSave);
  },
  toggleEditMode({ commit }, editMode: boolean): void {
    commit(CONSTANTS.EDIT_MODE, editMode);
    if (!editMode) {
      commit(CONSTANTS.ENABLE_SAVE, false);
    }
  },
  save(/* { commit } */): void {
    // console.log('save');
  },
  // async getConnections({ commit }, payload: CallRailConnections): Promise<object> {
  //   try {
  //     commit(CONSTANTS.SET_LOADING, true);
  //     commit(CONSTANTS.SET_ERROR, null);
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     const req: AxiosResponse<any> = await requests.getConnections(
  //       `/api/callRailGetConnections/${payload.id}/${payload.isAgency}/${payload.allPossible}/`,
  //     );

  //     if (req.data?.error) {
  //       commit(CONSTANTS.SET_ERROR, req.data.error);
  //       return;
  //     }
  //     return req.data;
  //   } catch (exp) {
  //     commit(CONSTANTS.SET_ERROR, exp.message);
  //     throw logError(exp, 'store.callrail.getConnections');
  //   } finally {
  //     commit(CONSTANTS.SET_LOADING, false);
  //   }
  // },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gqlGetProductsForAdvertiser(): Promise<any> {
    return new Promise((resolve, reject) => {
      apollo()
        .query({
          query: gql`
            query ExampleQuery($getProductsForAdvertiserId: ID) {
              getProductsForAdvertiser(id: $getProductsForAdvertiserId) {
                id
                AllowSingleCampaignView
              }
            }
          `,
          variables: {
            getProductsForAdvertiserId: '88888888',
          },
          fetchPolicy: 'no-cache',
        })
        .then(response => {
          // eslint-disable-next-line no-console
          console.log('gqlGetProductsForAdvertiser', response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
};
