import axios, { AxiosResponse } from 'axios';
import { StatusAlerts } from '../../../types/status';
import { session_id } from './../../../main';

export default {
  async getDashboardStatus(url: string) {
    try {
      const headers = { session_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await axios.get(url, { headers });
      return response;
    } catch (err) {
      return err;
    }
  },
  async setDashboardStatus(url: string, body: StatusAlerts) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await axios.post(url, body);
      return response;
    } catch (err) {
      return err;
    }
  },
};
