import { AxiosResponse } from 'axios';
import CONSTANTS from './constants';
import { logError } from './../../index';
import requests from './requests';
import { DimissedAlerts } from './types';
import { StatusAlerts } from '../../../types/status';

const getDashboardStatus = async ({ commit }): Promise<object> => {
  try {
    commit(CONSTANTS.SET_DASHBOARD_STATUS_ERROR, null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: AxiosResponse<any> = await requests.getDashboardStatus('/api/getDashboardStatus');
    if (response.data?.error) {
      commit(CONSTANTS.SET_DASHBOARD_STATUS_ERROR, response.data.error);
      return response.data;
    }
    commit(CONSTANTS.SET_DASHBOARD_STATUS, response.data);
    return response.data;
  } catch (exp) {
    commit(CONSTANTS.SET_DASHBOARD_STATUS_ERROR, exp.message);
    throw logError(exp, 'store.customer.getDashboardStatus');
  }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setDashboardStatus = async ({ commit }, params: StatusAlerts): Promise<any> => {
  if (!params) {
    const error = 'Missing params';
    commit(CONSTANTS.SET_DASHBOARD_STATUS_ERROR, error);
    logError(error, 'store.setDashboardStatus');
    return { data: null, error };
  }
  try {
    commit(CONSTANTS.SET_DASHBOARD_STATUS_ERROR, null);
    commit(CONSTANTS.SET_DASHBOARD_STATUS_LOADING, true);
    const url = `/api/setDashboardStatus`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: AxiosResponse<any> = await requests.setDashboardStatus(url, params);
    if (response.data?.body?.json) {
      commit(CONSTANTS.SET_DASHBOARD_STATUS_LOADING, false);
      commit(CONSTANTS.SET_DASHBOARD_STATUS, response.data.body.json);
      return response.data.body.json;
    }
    commit(CONSTANTS.SET_DASHBOARD_STATUS_LOADING, false);
    if (response.data?.error) {
      commit(CONSTANTS.SET_DASHBOARD_STATUS_ERROR, response.data.error);
      throw new Error(response.data.error);
    } else throw new Error('Failed request');
  } catch (err) {
    logError(err, 'store.setDashboardStatus catch');
    return { error: err.message };
  }
};

const setDismissedAlerts = ({ commit }, params: DimissedAlerts): void => {
  try {
    commit(CONSTANTS.SET_DASHBOARD_STATUS_DISMISS, params);
  } catch (err) {
    logError(err, 'store.setDismissedAlerts catch');
  }
};

const setDashboardLoading = ({ commit }, loading): void => {
  commit(CONSTANTS.SET_DASHBOARD_STATUS_LOADING, loading);
};

export default {
  getDashboardStatus,
  setDashboardStatus,
  setDashboardLoading,
  setDismissedAlerts,
};
