import axios from 'axios';
import store from '../store';
import utils from '../util';

export function setup() {
  let isRefreshing = false;
  let failedRequests = [];
  axios.interceptors.request.use(
    async config => {
      // TODO: track multiple requests
      // create bind with promise await that promise
      const currentAccessToken: string = store.getters.user.accessToken;
      if (currentAccessToken) {
        try {
          // console.log(`${new Date().toISOString().replace('T', ' ').slice(0, 23)} interceptors try`);
          const isAccessTokenExpired = utils.isAccessTokenExpired(currentAccessToken);
          // Check if the token is about to expire within REFRESH_THRESHOLD_IN_SECONDS
          if (isAccessTokenExpired) {
            // The token is about to expire or has already expired
            // Check if a token refresh is already in progress
            if (!isRefreshing) {
              isRefreshing = true;
              // console.log('interceptors new token');
              const freshAccessToken = utils.updateAccessToken();
              config.headers['ahauth'] = `Bearer ${freshAccessToken}`;
              isRefreshing = false;
              // Process the failed requests
              failedRequests.forEach(request => {
                request.config.headers['ahauth'] = `Bearer ${freshAccessToken}`;
                axios(request);
              });
              failedRequests = [];
            } else {
              // Token is being refreshed, queue the request
              const failedRequest = {
                resolve: null,
                reject: null,
                config: config,
              };
              const promise = new Promise((resolve, reject) => {
                failedRequest.resolve = resolve;
                failedRequest.reject = reject;
              });
              failedRequests.push(failedRequest);
              return promise;
            }
          } else {
            config.headers['ahauth'] = `Bearer ${currentAccessToken}`;
          }
        } catch (exp) {
          isRefreshing = false;
        }
      }
      return config;
    },
    err => {
      return Promise.reject(err);
    },
  );
  axios.interceptors.request.use(config => {
    const queryParams = new URLSearchParams(window.location.search);
    let skipCacheValue;

    if (queryParams.has('skipCache')) {
      skipCacheValue = queryParams.get('skipCache');
    }
    // Check if skipCache is already in the URL and set it to true or false
    if (skipCacheValue) {
      config.params = {
        ...(config.params || {}),
        skipCache: skipCacheValue,
      };
    }
    return config;
  });
}

export function countApiCalling(onFinish) {
  let interceptorsRegistered = false;
  let cleanupInterceptors = null;

  if (cleanupInterceptors) {
    cleanupInterceptors();
  }

  if (!interceptorsRegistered) {
    cleanupInterceptors = registerInterceptors(onFinish);
    interceptorsRegistered = true;
  }
}

function registerInterceptors(onFinish) {
  const activeRequests = new Map();
  let finishTimeout = null;
  let hasFinished = false;
  const EXCLUDED_URLS = ['/api/getDashboardStatus', '/api/getPolygons', '/api/auth'];

  const requestInterceptor = axios.interceptors.request.use(
    config => {
      if (!EXCLUDED_URLS.includes(config.url) && !hasFinished) {
        activeRequests.set(config.url, (activeRequests.get(config.url) || 0) + 1);
      }
      return config;
    },
    error => {
      Promise.reject(error);
    },
  );

  const responseInterceptor = axios.interceptors.response.use(
    response => {
      updateActiveRequests(response.config.url);
      return response;
    },
    error => {
      updateActiveRequests(error.config.url);
      Promise.reject(error);
      return Promise.reject(error);
    },
  );

  function updateActiveRequests(url) {
    if (!EXCLUDED_URLS.includes(url)) {
      const count = activeRequests.get(url) || 0;
      if (count > 1) {
        activeRequests.set(url, count - 1);
      } else {
        activeRequests.delete(url);
      }
      if (activeRequests.size === 0 && !hasFinished) {
        clearTimeout(finishTimeout);
        finishTimeout = setTimeout(() => {
          hasFinished = true;
          onFinish();
        }, 500);
      }
    }
  }

  return () => {
    axios.interceptors.request.eject(requestInterceptor);
    axios.interceptors.response.eject(responseInterceptor);
  };
}
