
import util from '../../util';
export default {
  name: 'status',
  props: ['dashboardAlertActive', 'tacticAlerts'],
  data(): {
    interval: Function;
  } {
    return {
      interval: null,
    };
  },
  mounted(): void {
    // on mount check for status if user logged in.
    if (this.$store.getters.user?.auth || this.$route?.name === 'login') {
      this.$store.dispatch('status/getDashboardStatus');
      // check again every 30 seconds.
      // todo: handle when user session expires while window is open
      this.interval = setInterval(
        function () {
          this.$store.dispatch('status/getDashboardStatus');
        }.bind(this),
        30000,
      );
    }
  },
  beforeDestroy(): void {
    clearInterval(this.interval);
  },
  computed: {
    multipleAlerts(): boolean {
      return this.tacticAlerts.length > 1;
    },
    showSystemBar(): boolean {
      // don't show dialog on admin page
      if (this.$route?.name === 'adminstatus') return false;
      // get status boolean from mixin.
      return this.dashboardAlertActive;
    },
    env(): string {
      return util.getEnvForProductLink(true);
    },
    formattedEnv(): string {
      let envName = this.env;
      switch (envName) {
        case 'local':
        case 'dev':
          break;
        case 'stg':
          envName = 'staging';
          break;
      }
      return envName.toUpperCase();
    },
    envBarStyle(): object {
      const colorsMapping = {
        LOCAL: '#77bb41',
        DEV: '#03c7fc',
        STAGING: '#ff9301',
      };
      return {
        backgroundColor: colorsMapping[this.formattedEnv],
        marginTop: this.dashboardAlertActive ? '40px' : '0px',
      };
    },
  },
  methods: {
    dismissAlert(): void {
      if (!this.multipleAlerts) {
        const currentDismiss = this.$store.state?.status?.dismiss || [];
        const dismissArray = [...currentDismiss];
        const { id, location } = this.tacticAlerts[0];
        dismissArray.push({ id, location });
        this.$store.dispatch('status/setDismissedAlerts', dismissArray).catch((error: Error) => {
          // eslint-disable-next-line no-console
          console.error('setDismissedAlerts error', error);
          return;
        });
      }
    },
  },
};
