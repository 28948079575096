import { ApolloQueryResult } from '@apollo/client/core';
import gql from 'graphql-tag';
import { apollo } from '../../index';
import {
  ArticleResponse,
  ArticlesResponse,
  SectionResponse,
  SectionsResponse,
  CategoriesResponse,
  FormResponse,
  SearchArticleResponse,
  FormRequest,
  FormRequestResponse,
} from './types';

export default {
  async getSCArticlesById(articleId: string) {
    try {
      const response: ApolloQueryResult<ArticleResponse> = await apollo().query({
        query: gql`
          query GetZenDeskArticleById($id: String) {
            getZenDeskArticleById(id: $id) {
              article {
                id
                section_id
                name
                title
                body
              }
            }
          }
        `,
        variables: {
          id: articleId,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSCArticlesByCategory(categoryId: string) {
    try {
      const response: ApolloQueryResult<ArticlesResponse> = await apollo().query({
        query: gql`
          query GetZenDeskArticlesByCategory($category: String) {
            getZenDeskArticlesByCategory(category: $category) {
              articles {
                id
                section_id
                name
                body
              }
              count
            }
          }
        `,
        variables: {
          category: categoryId,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSCArticlesBySection(sectionId: string) {
    try {
      const response: ApolloQueryResult<ArticlesResponse> = await apollo().query({
        query: gql`
          query GetZenDeskArticlesBySection($section: String) {
            getZenDeskArticlesBySection(section: $section) {
              articles {
                id
                section_id
                name
                title
                body
              }
              count
            }
          }
        `,
        variables: {
          section: sectionId,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSCFirstArticleBySectionId(sectionId: string) {
    try {
      const response: ApolloQueryResult<ArticleResponse> = await apollo().query({
        query: gql`
          query GetZenDeskFirstArticleBySectionId($section: String) {
            getZenDeskFirstArticleBySectionId(section: $section) {
              article {
                id
                name
                title
                body
              }
            }
          }
        `,
        variables: {
          section: sectionId,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSCSectionsById(sectionId: string) {
    try {
      const response: ApolloQueryResult<SectionResponse> = await apollo().query({
        query: gql`
          query GetZenDeskSectionsById($id: String) {
            getZenDeskSectionsById(id: $id) {
              section {
                id
                category_id
                name
                description
              }
            }
          }
        `,
        variables: {
          id: sectionId,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSCSections() {
    try {
      const response: ApolloQueryResult<SectionsResponse> = await apollo().query({
        query: gql`
          query GetZenDeskSections {
            getZenDeskSections {
              sections {
                id
                category_id
                name
                description
              }
            }
          }
        `,
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSCSectionsByCategoryId(categoryId: string) {
    try {
      const response: ApolloQueryResult<SectionsResponse> = await apollo().query({
        query: gql`
          query GetZenDeskSectionsByCategory($id: String) {
            getZenDeskSectionsByCategory(id: $id) {
              sections {
                id
                category_id
                name
                description
              }
            }
          }
        `,
        variables: {
          id: categoryId,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSCCategories() {
    try {
      const response: ApolloQueryResult<CategoriesResponse> = await apollo().query({
        query: gql`
          query GetZenDeskCategories($sortOrder: String) {
            getZenDeskCategories(sort_order: $sortOrder) {
              categories {
                name
                id
                description
              }
            }
          }
        `,
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSearchSCArticles(searchTerm: string, page: number) {
    try {
      const response: ApolloQueryResult<SearchArticleResponse> = await apollo().query({
        query: gql`
          query GetZenDeskArticlesByQuery($query: String, $page: Int) {
            getZenDeskArticlesByQuery(query: $query, page: $page) {
              page
              page_count
              count
              results {
                id
                name
                section_id
                result_type
                snippet
                body
              }
            }
          }
        `,
        variables: {
          query: searchTerm,
          page,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSCForms() {
    try {
      const response: ApolloQueryResult<CategoriesResponse> = await apollo().query({
        query: gql`
          query GetZenDeskTicketForms {
            getZenDeskTicketForms {
              ticket_forms {
                id
                name
              }
              count
            }
          }
        `,
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSCFormById(formId: string) {
    try {
      const response: ApolloQueryResult<FormResponse> = await apollo().query({
        query: gql`
          query GetZenDeskTicketFormById($id: String) {
            getZenDeskTicketFormById(id: $id) {
              form {
                id
                name
              }
              form_fields {
                id
                title_in_portal
                type
                required_in_portal
                description
                editable_in_portal
                custom_field_options {
                  name
                }
              }
            }
          }
        `,
        variables: {
          id: formId,
        },
        fetchPolicy: 'cache-first',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
  async postSCFormRequest(request: FormRequest) {
    try {
      const response: ApolloQueryResult<FormRequestResponse> = await apollo().mutate({
        mutation: gql`
          mutation PostZenDeskRequest($request: ZenDeskRequest!) {
            postZenDeskRequest(request: $request) {
              request {
                id
                description
                status
                subject
              }
            }
          }
        `,
        variables: {
          request: request,
        },
        fetchPolicy: 'no-cache',
      });
      return response;
    } catch (err) {
      return err;
    }
  },
};
